import { useConversationsSearchCount } from '@capturi/api-conversations'
import { useFirstPhoneSegmentState } from '@capturi/filters'
import { Box, Flex, Text, VStack, chakra } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

import { ConversationsMatchCount } from '../ConversationsMatchCount'
import { SearchChipsInput } from './SearchChipsInput'
import { useSearchPhrasesContext } from './useSearchPhrases'

export const SearchInputWithCounters: React.FC = () => {
  const [searchPhrases] = useSearchPhrasesContext()
  const segmentState = useFirstPhoneSegmentState()
  const filter = segmentState.getFilterRequestModel()
  const {
    data: searchConversationsCount,
    isLoading: isSearchConversationsCountLoading,
  } = useConversationsSearchCount({
    filter,
    searchPhrases,
    enabled: searchPhrases !== undefined && searchPhrases.length > 0,
  })

  const conversationCount = searchConversationsCount?.count ?? 0
  const numberOfConversationsSearched =
    searchPhrases.length > 0 &&
    searchConversationsCount !== undefined &&
    searchConversationsCount.numberOfConversationsSearched !== null
      ? searchConversationsCount.numberOfConversationsSearched
      : null
  const conversationSearchCountPostfix =
    numberOfConversationsSearched !== null
      ? `/ ${numberOfConversationsSearched}`
      : ''

  return (
    <>
      <Flex alignItems="center" h={8}>
        {searchPhrases !== undefined &&
        searchPhrases.length > 0 &&
        searchConversationsCount !== undefined ? (
          <Text fontSize="sm">
            <Trans>
              Conversations: {conversationCount}
              {conversationSearchCountPostfix}
            </Trans>
          </Text>
        ) : (
          <ConversationsMatchCount />
        )}
      </Flex>

      <Box maxW="34rem" minW="16rem" flexGrow={1}>
        <VStack alignItems="flex-end" gap={1}>
          <SearchChipsInput isLoading={isSearchConversationsCountLoading} />
          {(numberOfConversationsSearched !== null ||
            isSearchConversationsCountLoading) && (
            <Text textColor="gray.600" fontSize="sm" fontStyle="italic">
              <Trans>
                Searching in the most recent{' '}
                <chakra.span fontWeight="bold">
                  {numberOfConversationsSearched}
                </chakra.span>{' '}
                conversations within the filter and period.
              </Trans>
            </Text>
          )}
        </VStack>
      </Box>
    </>
  )
}
