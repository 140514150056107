import constate from 'constate'
import qs from 'query-string'
import { useCallback, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

const QUERY_KEY = 'q'

function useSearchPhrases(): [string[], (tags: string[]) => void] {
  const [searchParams, setSearchParams] = useSearchParams()
  const [phrases, setPhrases] = useState(() => {
    // read query param initially and set state
    const parsedQuery = qs.parse(window.location.search)
    const value = parsedQuery[QUERY_KEY]
    if (value == null) {
      return []
    }
    if (typeof value === 'string') {
      return [value]
    }
    return value.filter(Boolean) as string[]
  })

  const handleChange = useCallback(
    (tags: string[]) => {
      const parsedQueryString = qs.parse(searchParams.toString())
      const newParams = qs.stringify({
        ...parsedQueryString,
        q: tags,
      })

      setSearchParams(newParams, { replace: true })
      setPhrases(tags)
    },
    [searchParams, setSearchParams],
  )

  return [phrases, handleChange]
}

export const [SearchPhrasesProvider, useSearchPhrasesContext] =
  constate(useSearchPhrases)
